import React, { useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import {RichText, Date} from 'prismic-reactjs';

import GlobalContext from '../../context/GlobalContext'
import {
  Section,
  Text,
  ButtonIcon,
  TextArea,
  Title,
} from '../../components/Core'
import { device } from '../../utils'

const StyledSection = styled(Section)`
  @media ${device.md} {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  @media ${device.sm} {
    padding-top: 15px;
    margin-top: 4rem;

    margin-left: 1rem;
    margin-right: 1rem;

    .topline {
      display: block;
      border-top: 1px solid #707070;
    }
  }

  .topline {
    display: none;
  }

  .label {
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
  }

  .copy {
    margin: 50px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
    text-align: center;
  }

  .copy p{
    min-height: 20px;
  }

  .page-copy {
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
    margin-bottom: 30px;
  }

  .copy-block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .page-title {
    margin-bottom: 20px;
    font-family: 'Vijaya';

    font-size: 35px;
    line-height: 40px;
  }

  .page-title {
    padding-top: 10px;
  }

  @media ${device.lg} {
    .page-title {
      padding-top: 10px;
      margin-bottom: 20px;
      font-size: 60px;
      line-height: 75px;
    }

    .br {
      border-right: 1px solid black;
      padding-right: 80px;
    }
  }
`

const StyledInput = styled.input`
  margin-bottom: 15px;
  width: 100%;
  height: 40px;
  border-radius: 0px;
  border: 1px solid black;
`

const StyledTextArea = styled.textarea`
  margin-bottom: 30px;
  width: 100%;
  height: 80px;
  border-radius: 0px;
  border: 1px solid black;
`

const SubmitButton = styled.button`
  display: inline-block;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  font-size: 20px;
  line-height: 25px;
  font-weight: 200;
  text-transform: uppercase;
  border: 1px solid #707070;
  color: black;
`

const TopLine = styled.span`
  display: none;
  top: 0px;
  width: 50vw;
  height: 10px;
  border-top: 1px solid #707070;

  padding-bottom: 35px;
  @media ${device.sm} {
    display: inline-table;
  }
`

const Hero = ({doc}) => {
  return (
    <>
      <StyledSection>
        <Container>
          <Row>
            <Col lg={6} className="br">
              <Title className="page-title">We work better face-to-face</Title>
              <Text className="page-copy">
              {RichText.render(doc.data.copy_left.raw)}
              </Text>
              <form name="contact" method="POST" netlify netlify-honeypot="bot-field" action="/thankyou/"   data-netlify="true">
              <p style={{display: 'none'}}>
                <label>
                  Don’t fill this out if you’re human:{" "}
                  <input name="bot-field" />
                </label>
              </p>

              <input type="hidden" name="form-name" value="contact" />

              <Text className="label">First Name</Text>
              <StyledInput type="text" name="first_name" />
              <Text className="label">Last Name</Text>
              <StyledInput type="text" name="last_name" />
              <Text className="label">Email Address</Text>
              <StyledInput type="email" name="email"  />
              <Text className="label">Phone Number</Text>
              <StyledInput type="tel" name="phone" />
              <Text className="label">Message</Text>
              <StyledTextArea rows="4" name="message" />

              <SubmitButton className='hover' type="submit">Submit</SubmitButton>
              </form>

            </Col>
            <Col lg={6} className="copy-block">
              <Text className="copy">
                <TopLine />
                {RichText.render(doc.data.copy_right.raw)}

              </Text>
            </Col>
          </Row>
        </Container>
      </StyledSection>
    </>
  )
}

export default Hero
