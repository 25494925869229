import React from 'react'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/landing1/Contact'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

const ServicesPage = ({data}) => {
  return (
    <>
      <PageWrapper darkTheme={false}>
        <SEO title={data.prismicContact.data.title.text} description={data.prismicContact.data.meta_description.text}/>
        <Contact doc={data.prismicContact}/>
      </PageWrapper>
    </>
  )
}
export default ServicesPage


export const query = graphql`
query QueryContact {
  prismicContact {
    data {
      title {
        text
      }
      meta_description {
        text
      }
      copy_right {
        raw
      }
      copy_left {
        raw
      }
    }
  }
}

`
